<script>
import md5 from "md5";

import ComplexShipSchedule from "./ComplexShipSchedule.vue";
import ComplexAdditionalService from "./ComplexAdditionalService.vue";
import ComplexRateVariants from "./ComplexRateVariants";
import RateCardNotFound from "../rate-list/RateCardNotFound";
import LoginForm from "../common/LoginForm";
import Modal from "../common/Modal";
import AppWarningPlate from "@/components/ui/AppWarningPlate.vue";
import RequestForm from "@/components/request-form/RequestForm.vue";
import {bus} from "@/router/bus";
import AppCheckbox from '@/components/ui/AppCheckbox.vue';

export default {
  name: 'RateComplexList',
  
  components:{
    ComplexShipSchedule,
    ComplexAdditionalService,
    ComplexRateVariants,
    AppWarningPlate,
    RequestForm,
    Modal,
    LoginForm,
    RateCardNotFound,
    AppCheckbox
  },
  
  data: () => ({
    showLoadingList: false,
    send_rate_request_data: {},
    popupVisible: false,
    showOrderRequestForm: false,
    showLoginForm: false,
    
    is_found: null,
    
    is_showvars: false,
    variants: [],
    showloaderList: 0,
    is_show_20: true,
    is_show_24: true,
    is_show_40: true,
    is_show_agent: false,
    is_show_auto: false,
    place_from_ids: [],
    line_ids: [],
    place_to_ids: [],
    terminal_ids: [],
    station_ids: [],
    unit_code: null,
    on_date: null,
    params: [],
    agent_cost: 0,
    cid: 0,
    ratelist: {
      coc_rates:[],
      soc_rates:[],
      ete_rates:[],
      dtn_rates:[],
    },
    cpx_ship_schedule: [],
    cpx_additional_expences: {
      detention: [],
      port_storage: [],
      port_additional: [],
      station_storage: [],
      station_additional: [],
    },
    
    auto:[
      {
        warehouse: "Автовывоз со станции МСК на адрес Люберцы, рп Томилино, 23-й км Новорязанского ш., к 17, стр 3",
        key: md5("Автовывоз со станции МСК на адрес Люберцы, рп Томилино, 23-й км Новорязанского ш., к 17, стр 3"),
        data:[
          { comment: "20DC до 18т брутто", price: 38500 },
          { comment: "40HQ до 20т брутто", price: 40500 },
          { comment: "с 20001 кг до 22000 кг", price: 2000 },
          { comment: "с 22001 кг до 24000 кг", price: 3000 },
          { comment: "с 24001 кг до 26000 кг", price: 4000 },
          { comment: "простой под выгрузкой (сверх 5 часов)", price: 1800 },
        ],
      },
      {
        warehouse: "Автовывоз со станции СПБ на адрес г. Москва, Люберцы, рп Томилино, 23-й км Новорязанского ш., к 17, стр 3",
        key: md5("Автовывоз со станции СПБ на адрес г. Москва, Люберцы, рп Томилино, 23-й км Новорязанского ш., к 17, стр 3"),
        data:[
          { comment: "40HQ до 20т брутто", price: 85000 },
        ],
      }
    ],
    
    requestRates: [],
  }),
  
  created() {
    this.cid = this.$_getsetting('client_id') ? this.$_getsetting('client_id') : -99
  },
  
  computed: {
    isAuth() {
      if (this.$_getsetting('is_auth')) {
        return true
      } else {
        return false
      }
    },

    checkComplexRateList(){
      return Object.values(this.ratelist).some(array => array.length > 0)
    }
  },
  
  mounted(){
    bus.$on("getcomplexrates", (args) => {
      this.getdata(args)
    });
    
    bus.$on("show_send_request", (args) => {
      this.send_rate_request_data = args
      this.is_showvars = false
      if (!this.isAuth) {
        this.showLoginForm = true
      } else {
        this.showOrderRequestForm = true
      }
    });
  },

  methods: {
    showVariants(value){
      this.showloaderList = true
      this.variants = value.variants
      this.is_showvars = true
      this.showloaderList = false
    },
    exporttoexcel(){
      window.open(this.createlink())
    },
    
    createlink(){
      return "https://rest.tramis.ru/index.php?_url=/getvdxkpext/getRateReport/" +
        this.on_date.toISOString().slice(0, 10) + "/" +
        this.params.place_from + "/" +
        this.params.place_to + "/" +
        this.params.unit_code + "/" +
        this.cid + "/" +
        this.params.line_id + "/" +
        ((this.is_show_20) ? 1 : 0) + "/" +
        ((this.is_show_24) ? 1 : 0) + "/" +
        ((this.is_show_40) ? 1 : 0) + "/" +
        ((this.is_show_agent) ? 1 : 0) + "/" +
        this.agent_cost
    },
    
    addauto(){
      alert('addauto')
    },
    
    editauto(key){
      alert(key)
    },
    
    deleteauto(key){
      this.auto = this.auto.filter(d => d.key != key);
    },
    
    getkey(item){
      return md5(item.base_place_from_name + item.base_place_to_name)
    },
    
    getnumber(value) {
      return parseFloat(Math.ceil(value)).toLocaleString("ru-RU");
    },
    
    getdate(value) {
      if (value != null)
        return (new Date(value)).toLocaleString("ru", {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          timezone: 'UTC'
        });
      else
        return ' '
    },
    
    getdata(data){
      this.showloaderList = 1
      let out_date = new Date(data.on_date)
      out_date.setDate(out_date.getDate());
      this.on_date = out_date
      this.unit_code = data.unit_code
      this.params = data;
      this.$postapi(this.$address + this.$getters.getComplexRateList.uri, {
        method: this.$getters.getComplexRateList.name,
        on_date: out_date,
        place_to: data.place_to,
        place_from: data.place_from,
        unit_code: data.unit_code,
        line_id: data.line_id,
        client_id: data.client_id,
        token: this.$_getsetting('token'),
      }).then(data => {
        if (data.error == 0) {
          this.ratelist = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
          this.getOtherRequestParameters()
          this.showloaderList = 0;
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    getScheduleData(){
      this.$postapi(this.$address + this.$getters.getComplexShipSchedule.uri, {
        method: this.$getters.getComplexShipSchedule.name,
        on_date: this.on_date,
        place_to_ids: this.terminal_ids.join() + this.station_ids.join(),
        place_from_ids: this.place_from_ids.join(),
        line_ids: this.line_ids.join()
      }).then(data => {
        if (data.error == 0) {
          this.cpx_ship_schedule = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
          this.showloaderList = 0;
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    getAdditionalExpensesData(){
      this.$postapi(this.$address + this.$getters.getComplexAdditionalExpences.uri, {
        method: this.$getters.getComplexAdditionalExpences.name,
        on_date: this.on_date,
        place_to_ids: this.place_to_ids.join(),
        point_to_ids: this.terminal_ids.join() + this.station_ids.join(),
        line_ids: this.line_ids.join(),
        unit_code: this.unit_code
      }).then(data => {
        if (data.error == 0) {
          this.cpx_additional_expences = JSON.parse(data.message.replace(/:"f",/gm, `:false,`).replace(/:"t",/gm, `:true,`))
          this.showloaderList = 0;
        } else {
          bus.$emit("show_notify", {
            color: "error",
            notifytext: data.message
          });
        }
      });
    },
    
    getOtherRequestParameters(){
      this.place_from_ids = []
      this.line_ids = []
      this.place_to_ids = []
      this.terminal_ids = []
      this.station_ids = []
      for (let i = 0; i < this.ratelist.coc_rates.length; i++){
        this.place_from_ids.push(this.ratelist.coc_rates[i].place_from_ids)
        this.line_ids.push(this.ratelist.coc_rates[i].line_ids)
        this.place_to_ids.push(this.ratelist.coc_rates[i].place_to_ids)
        this.terminal_ids.push(this.ratelist.coc_rates[i].terminal_ids)
        this.station_ids.push(this.ratelist.coc_rates[i].station_ids)
      }
      for (let i = 0; i < this.ratelist.soc_rates.length; i++){
        this.place_from_ids.push(this.ratelist.soc_rates[i].place_from_ids)
        this.line_ids.push(this.ratelist.soc_rates[i].line_ids)
        this.place_to_ids.push(this.ratelist.soc_rates[i].place_to_ids)
        this.terminal_ids.push(this.ratelist.soc_rates[i].terminal_ids)
        this.station_ids.push(this.ratelist.soc_rates[i].station_ids)
      }
      for (let i = 0; i < this.ratelist.ete_rates.length; i++){
        this.place_from_ids.push(this.ratelist.ete_rates[i].place_from_ids)
        this.line_ids.push(this.ratelist.ete_rates[i].line_ids)
        this.place_to_ids.push(this.ratelist.ete_rates[i].place_to_ids)
        this.terminal_ids.push(this.ratelist.ete_rates[i].terminal_ids)
        this.station_ids.push(this.ratelist.ete_rates[i].station_ids)
      }
      for (let i = 0; i < this.ratelist.dtn_rates.length; i++){
        this.place_from_ids.push(this.ratelist.dtn_rates[i].place_from_ids)
        this.line_ids.push(this.ratelist.dtn_rates[i].line_ids)
        this.place_to_ids.push(this.ratelist.dtn_rates[i].place_to_ids)
        this.terminal_ids.push(this.ratelist.dtn_rates[i].terminal_ids)
        this.station_ids.push(this.ratelist.dtn_rates[i].station_ids)
      }
      this.getScheduleData()
      this.getAdditionalExpensesData()
    },
    checkRateList(obj){
      let countRateArr = []
      for(let key in obj){
        if(obj[key].length > 0){
          countRateArr.push(obj[key])
        }
      }
      countRateArr.length > 0 ? this.is_found = true : this.is_found = false
    },
  },

  watch:{
    ratelist(){
      this.checkRateList(this.ratelist)
    }
  },
}
</script>

<template>
  <div class="complex-rate-container">
   <v-dialog
      v-model="showOrderRequestForm"
      :showModal="showOrderRequestForm"
      persistent
      transition="none"
      overlay-color="rgba(67, 79, 91, 0.80)"
      style="border-radius: 32px !important; box-shadow: none !important"
      max-width="1400px"
    >
      <RequestForm
        v-if="showOrderRequestForm"
        class="request-form"
        :args="send_rate_request_data"
        @close="showOrderRequestForm = false"
        :date="on_date"
      />
    </v-dialog>

    <Modal :showModal="showLoginForm">
      
      <LoginForm
        class="request-form"
        type="login"
        @close="showLoginForm = false"
      />
    </Modal>

    <v-dialog
      v-model="is_showvars"
      width="60%"
      height="60%"
      transition="dialog-bottom-transition"
      @click:outside="is_showvars = false, variants = []"
    >
      <ComplexRateVariants
        v-if="is_showvars === true"
        :variants="variants"
        :cid="cid"
        :on_date="on_date"
      />
    </v-dialog>

    <AppWarningPlate
      class="compex-rate-page-warning"
      v-if="is_found && !showloaderList"
      message="На сайте размещены тарифы перевозок, очищенные от НДС.
      Обложение оказываемых экспедиторами услуг налогом на добавленную стоимость осуществляется в соответствии с действующим законодательством Российской Федерации"
    />
    
    <div class="compex-rate-page-header-block">
      
      <div class="excel-link">
        <a @click="exporttoexcel">Выгрузить в Excel</a>
      </div>
      
      <div class="rate-complex-advansed-parameters">
        <h3>Параметры отображения:</h3>
        
        <div class="rate-complex-advansed-parameters-elements">
          <div class="rate-complex-advansed-parameters-element">
            <AppCheckbox
              v-if="is_show_auto"
              label="Показать/скрыть блок автовывоза"
              v-model="is_show_auto"
            />
          </div>
          
          <div class="rate-complex-advansed-parameters-element">
            <AppCheckbox
              label="Показать/скрыть тарифы на 20'DV"
              v-model="is_show_20"
            />
          </div>
          
          <div class="rate-complex-advansed-parameters-element">
            <AppCheckbox
               label="Показать/скрыть тарифы на тяжелые 20'DV"
              v-model="is_show_24"
            />
          </div>
          
          <div class="rate-complex-advansed-parameters-element">
            <AppCheckbox
              label="Показать/скрыть тарифы на 40'HC"
              v-model="is_show_40"
            />
          </div>

          <div class="rate-complex-advansed-parameters-element">
            <v-text-field
              class="rate-complex-advansed-parameters-element__text"
              v-if="is_show_agent"
              v-model="agent_cost"
              label="Тариф агента"
              color="var(--main-bg-color)"
            ></v-text-field>
          </div>
        </div>
      </div>
    </div>
    
    <div class="rate-loader-block" v-if="showloaderList == 1">
      <v-progress-circular
        :size="100"
        :width="10"
        color="var(--main-orange)"
        indeterminate
      ></v-progress-circular>
    </div>
    
    <div class="complex-rate-list" v-if="is_found">
      
      <div
        class="complex-rate-list-block"
        v-if="ratelist.coc_rates.length > 0"
      >
        <h3 class="block-header block-header-main">COC предложения:</h3>
        <div
          v-for="item in ratelist.coc_rates"
          :key="getkey(item)"
        >
          
          <h4 class="block-header block-header-sub">
            Международная доставка по маршруту&nbsp;{{item.base_place_from_name}}&nbsp; - &nbsp;{{item.base_place_to_name}}
          </h4>
          <table
            id="coc_rates_tbl"
            class="rate-card-detail-table"
          >
            <thead class="rate-card-detail-table-head">
            <tr>
              <td class="td-center">Линия</td>
              <td class="td-center">Экспедитор</td>
              <td class="td-center">Порт прибытия</td>
              <td class="td-center width-18">Ж/д станция</td>
              <td class="td-center">Действует до</td>
              <td v-if="is_show_agent" class="td-center">Агент, &#36;</td>
              <td v-if="is_show_20" class="td-center">Фрахт 20'DV, &#36;</td>
              <td v-if="is_show_24" class="td-center">Фрахт тяж 20'DV, &#36;</td>
              <td v-if="is_show_40" class="td-center">Фрахт 40'HC, &#36;</td>
              <td v-if="is_show_20" class="td-center">Дроп 20'DV, &#36;</td>
              <td v-if="is_show_40" class="td-center">Дроп 40'HC, &#36;</td>
              <td v-if="is_show_20" class="td-center">Ж/д 20'DV, &#8381;</td>
              <td v-if="is_show_24" class="td-center">Ж/д тяж 20'DV, &#8381;</td>
              <td v-if="is_show_40" class="td-center">Ж/д 40'HC, &#8381;</td>
              <td class="td-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Курс</td>
              <td v-if="is_show_20" class="td-center">Итого 20'DV, &#8381;</td>
              <td v-if="is_show_24" class="td-center">Итого тяж 20'DV, &#8381;</td>
              <td v-if="is_show_40" class="td-center">Итого 40'HC, &#8381;</td>
            </tr>
            </thead>
            
            <tr
              class="rate-card-detail-table-tr"
              v-for="element in item.data"
              :key="element.key"
              @click="showVariants(element)"
            >
              <td>{{element.line_name}}</td>
              <td>{{element.charterer_name}}</td>
              <td>{{element.terminal_name}}</td>
              <td>{{element.station_name}}</td>
              <td class="td-right">{{getdate(element.date_to)}}</td>
              <td v-if="is_show_agent" class="td-right">{{getnumber(agent_cost)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber(element.fright_20)}}</td>
              <td v-if="is_show_24" class="td-right">{{getnumber(element.fright_24)}}</td>
              <td v-if="is_show_40"  class="td-right">{{getnumber(element.fright_40)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber(element.drop_20)}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber(element.drop_40)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber(element.zd_20)}}</td>
              <td v-if="is_show_24" class="td-right">{{getnumber(element.zd_24)}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber(element.zd_40)}}</td>
              <td class="td-right">{{getnumber(element.cbr_rate)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_20 : element.total_20 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
              <td v-if="is_show_24" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_24 : element.total_24 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_40 : element.total_40 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
            </tr>
          </table>
        </div>
      </div>
      
      <div
        class="complex-rate-list-block"
        v-if="ratelist.soc_rates.length > 0"
      >
        <h3 class="block-header block-header-main">SOC предложения:</h3>
        <div v-for="item in ratelist.soc_rates" :key="getkey(item)">
          <h4 class="block-header block-header-sub">Международная доставка по маршруту&nbsp;{{item.base_place_from_name}}&nbsp; - &nbsp;{{item.base_place_to_name}}</h4>
          <table id="soc_rates_tbl" class="rate-card-detail-table">
            <thead class="rate-card-detail-table-head">
            <tr >
              <td class="td-left">Линия</td>
              <td class="td-left">Экспедитор</td>
              <td class="td-left">Арендодатель КТК</td>
              <td class="td-left">Порт прибытия</td>
              <td class="td-left width-20">Ж/д станция</td>
              <td class="td-right">Действует до</td>
              <td v-if="is_show_agent" class="td-right">Агент, &#36;</td>
              <td v-if="is_show_20" class="td-right">Фрахт 20'DV, &#36;</td>
              <td v-if="is_show_24" class="td-right">Фрахт тяж 20'DV, &#36;</td>
              <td v-if="is_show_40" class="td-right">Фрахт 40'HC, &#36;</td>
              <td v-if="is_show_20" class="td-right">Аренда 20'DV, &#36;</td>
              <td v-if="is_show_40" class="td-right">Аренда 40'HC, &#36;</td>
              <td v-if="is_show_20" class="td-right">Ж/д 20'DV, &#8381;</td>
              <td v-if="is_show_24" class="td-right">Ж/д тяж 20'DV, &#8381;</td>
              <td v-if="is_show_40" class="td-right">Ж/д 40'HC, &#8381;</td>
              <td class="td-right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Курс</td>
              <td v-if="is_show_20" class="td-right">Итого 20'DV, &#8381;</td>
              <td v-if="is_show_24" class="td-right">Итого тяж 20'DV, &#8381;</td>
              <td v-if="is_show_40" class="td-right">Итого 40'HC, &#8381;</td>
            </tr>
            </thead>
            
            <tr
              v-for="element in item.data"
              :key="element.key"
              @click="showVariants(element)"
            >
              <td>{{element.line_name}}</td>
              <td>{{element.charterer_name}}</td>
              <td>{{element.landlord_name}}</td>
              <td>{{element.terminal_name}}</td>
              <td>{{element.station_name}}</td>
              <td class="td-right">{{getdate(element.date_to)}}</td>
              <td v-if="is_show_agent" class="td-right">{{getnumber(agent_cost)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber(element.fright_20)}}</td>
              <td v-if="is_show_24" class="td-right">{{getnumber(element.fright_24)}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber(element.fright_40)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber(element.rent_20)}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber(element.rent_40)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber(element.zd_20)}}</td>
              <td v-if="is_show_24" class="td-right">{{getnumber(element.zd_24)}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber(element.zd_40)}}</td>
              <td class="td-right">{{getnumber(element.cbr_rate)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_20 : element.total_20 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
              <td v-if="is_show_24" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_24 : element.total_24 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_40 : element.total_40 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
            </tr>
          </table>
        </div>
      </div>
      
      <div
        class="complex-rate-list-block"
        v-if="ratelist.ete_rates.length > 0"
      >
        <h3 class="block-header block-header-main">Сквозные сервисы:</h3>
        <div v-for="item in ratelist.ete_rates" :key="getkey(item)">
          <h4 class="block-header block-header-sub">Международная доставка по маршруту&nbsp;{{item.base_place_from_name}}&nbsp; - &nbsp;{{item.base_place_to_name}}</h4>
          <table id="ete_rates_tbl" class="rate-card-detail-table">
            <thead class="rate-card-detail-table-head">
            <tr>
              <td class="td-left">Линия</td>
              <td class="td-left">Экспедитор</td>
              <td class="td-left width-20">Ж/д станция</td>
              <td class="td-right">Действует до</td>
              <td v-if="is_show_agent" class="td-right">Агент, &#36;</td>
              <td v-if="is_show_20" class="td-right">Фрахт 20'DV, &#36;</td>
              <td v-if="is_show_24" class="td-right">Фрахт тяж 20'DV, &#36;</td>
              <td v-if="is_show_40" class="td-right">Фрахт 40'HC, &#36;</td>
              <td class="td-right">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Курс</td>
              <td v-if="is_show_20" class="td-right">Итого 20'DV, &#8381;</td>
              <td v-if="is_show_24" class="td-right">Итого тяж 20'DV, &#8381;</td>
              <td v-if="is_show_40" class="td-right">Итого 40'HC, &#8381;</td>
            </tr>
            </thead>
            
            <tr
              v-for="element in item.data"
              :key="element.key"
              @click="showVariants(element)"
            >
              <td>{{element.line_name}}</td>
              <td>{{element.charterer_name}}</td>
              <td>{{element.station_name}}</td>
              <td class="td-right">{{getdate(element.date_to)}}</td>
              <td v-if="is_show_agent" class="td-right">{{getnumber(agent_cost)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber(element.fright_20)}}</td>
              <td v-if="is_show_24" class="td-right">{{getnumber(element.fright_24)}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber(element.fright_40)}}</td>
              <td class="td-right">{{getnumber(element.cbr_rate)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_20 : element.total_20 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
              <td v-if="is_show_24" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_24 : element.total_24 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_40 : element.total_40 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
            </tr>
          </table>
        </div>
      </div>
      
      <div
        class="complex-rate-list-block"
        v-if="ratelist.dtn_rates.length > 0"
      >
        <h3 class="block-header block-header-main">Прямые поезда:</h3>
        <div v-for="item in ratelist.dtn_rates" :key="getkey(item)">
          <h4 class="block-header block-header-sub">Международная доставка по маршруту&nbsp;{{item.base_place_from_name}}&nbsp; - &nbsp;{{item.base_place_to_name}}</h4>
          <table id="dtn_rates_tbl" class="rate-card-detail-table">
            <thead class="rate-card-detail-table-head">
            <tr>
              <td class="td-left">Линия</td>
              <td class="td-left">Экспедитор</td>
              <td class="td-left width-20">Ж/д станция</td>
              <td class="td-right">Действует до</td>
              <td v-if="is_show_agent" class="td-center">Агент, &#36;</td>
              <td v-if="is_show_20" class="td-right">Ж/д 20'DV, &#8381;</td>
              <td v-if="is_show_24" class="td-right">Ж/д тяж 20'DV, &#8381;</td>
              <td v-if="is_show_40" class="td-right">Ж/д 40'HC, &#8381;</td>
              <td class="td-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Курс</td>
              <td v-if="is_show_20" class="td-right">Итого 20'DV, &#8381;</td>
              <td v-if="is_show_24" class="td-right">Итого тяж 20'DV, &#8381;</td>
              <td v-if="is_show_40" class="td-right">Итого 40'HC, &#8381;</td>
            </tr>
            </thead>
            <tr
              v-for="element in item.data"
              :key="element.key"
              @click="showVariants(element)"
            >
              <td>{{element.line_name}}</td>
              <td>{{element.charterer_name}}</td>
              <td>{{element.station_name}}</td>
              <td class="td-right">{{getdate(element.date_to)}}</td>
              <td v-if="is_show_agent" class="td-right">{{getnumber(agent_cost)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber(element.fright_20)}}</td>
              <td v-if="is_show_24" class="td-right">{{getnumber(element.fright_24)}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber(element.fright_40)}}</td>
              <td class="td-right">{{getnumber(element.cbr_rate)}}</td>
              <td v-if="is_show_20" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_20 : element.total_20 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
              <td v-if="is_show_24" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_24 : element.total_24 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
              <td v-if="is_show_40" class="td-right">{{getnumber((is_show_agent == false ) ? element.total_40 : element.total_40 + (parseFloat(agent_cost) * parseFloat(element.cbr_rate)))}}</td>
            </tr>
          </table>
        </div>
      </div>
      
      <div class="complex-rate-list-block" v-if="is_show_auto">
        <div class="complex-auto-rate-header">
          <v-btn x-small @click="addauto()">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <h3 class="block-header block-header-auto">Автовывоз:</h3>
        </div>
        <div v-for="item in auto" :id="item.key" :key="item.key">
          <div class="complex-auto-rate-header">
            <v-btn x-small @click="deleteauto(item.key)"><v-icon>mdi-minus</v-icon></v-btn>
            <v-btn x-small @click="editauto(item.key)"><v-icon>mdi-pencil</v-icon></v-btn>
            <h4 class="block-header block-header-auto">{{item.warehouse}}</h4>
          </div>
          <table class="rate-card-detail-table">
            <tr v-for="element in item.data" :key="element.key">
              <td>{{element.comment}}</td>
              <td class="td-right">{{getnumber(element.price)}}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="complex-ship-schedule" v-if="cpx_ship_schedule.length > 0">
        <ComplexShipSchedule :shedules="cpx_ship_schedule" title="Расписание:"/>
      </div>
      <div
        class="complex-additional-service"
        v-if="cpx_additional_expences.detention.length > 0"
      >
        <ComplexAdditionalService
          :data="cpx_additional_expences.detention"
          title="Сверхнормативное использование контейнера:"
          block_type="0"
        />
      </div>
      
      <div
        class="complex-additional-service"
        v-if="cpx_additional_expences.port_storage.length > 0"
      >
        <ComplexAdditionalService
          :data="cpx_additional_expences.port_storage"
          title="Хранение в порту прибытия:"
          block_type="0"
        />
      </div>
      
      <div
        class="complex-additional-service"
        v-if="cpx_additional_expences.port_additional.length > 0"
      >
        <ComplexAdditionalService
          :data="cpx_additional_expences.port_additional"
          title="Дополнительные услуги в порту прибытия:"
          block_type="1"
        />
      </div>
      
      <div
        class="complex-additional-service"
        v-if="cpx_additional_expences.station_storage.length > 0"
      >
        <ComplexAdditionalService
          :data="cpx_additional_expences.station_storage"
          title="Хранение на станции прибытия:"
          block_type="0"
        />
      </div>
      
      <div
        class="complex-additional-service"
        v-if="cpx_additional_expences.station_additional.length > 0"
      >
        <ComplexAdditionalService
          :data="cpx_additional_expences.station_additional"
          title="Дополнительные услуги на станции прибытия:"
          block_type="1"
        />
      </div>
    </div>
    
    <div
      v-else-if="!checkComplexRateList && !showloaderList"
      class="rate-list__main-block__card-container"
    >
      <RateCardNotFound :currentList="'complex'" :parameters="params"/>
    </div>
  </div>
</template>

<style scoped lang="scss">
.complex-rate-container{
  margin: 0 auto;
  width: 100%;
  padding-bottom: 10%;
}
.rate-card-detail-table {
  width: 100%;
  font-size: 11pt;
  padding-left: 1.5%;
  margin: auto;
  border-collapse: collapse;
}

.modal-rate {
  width: 50%;
  height: 80%;
}

.complex-rate-list-block {
  margin: auto auto 16px;
  padding: 0.85% 0;
  background: #FFFFFF;
  display: flex;
  width: 100%;
  flex-direction: column;
  border: 1px solid #e6eae9;
  border-radius: 10px;
}

.rate-loader-block {
  margin: 20px auto;
  left:  calc(50% - 50px);
  position: absolute;
}

.rate-card-detail-table-head{
  font-size: 11px;
  text-align: center;
  font-weight: bold;
}
.td-right{
  text-align: right;
  border-right: 1px solid #e6eae9;
  padding-right: 0.425%;
}
.td-left{
  text-align: left;
}
.td-center{
  text-align: center;
}
.rate-card-detail-table tr {
  line-height: 25px;
}

.rate-card-detail-table td{
  border-bottom: 1px solid #e6eae9;
}
.block-header-main{
  width: 27%;
}
.block-header-auto{
  width: 100%;
}
.block-header-sub{
  width: 47%;
}
.block-header{
  margin-left: 1.61%;
  border-bottom: 1px solid #e6eae9;
}

td {
  padding: 12px 0;
}

tr:nth-child(even) {
  background: #F1F4F6;
}

tr:not(:first-child):hover {
  background: #DBE3E8;
  cursor: pointer;
}

.complex-auto-rate-header{
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
}
.width-20{
  width: 27%;
}
.button-right{
  margin-left: 1.61%;
}
.rate-complex-advansed-parameters{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  margin-left: 1.61%;
}
.rate-complex-advansed-parameters-elements{
  width: 97%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin: 15px 0;
}

.compex-rate-page-header{
  margin: 0 0 20px 1.61%;
  font-weight: 800;
  font-size: 44px;
  line-height: 60px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
}
.rate-complex-advansed-parameters-element{
  margin: 0;
}

.mt-editor-form {
  background-color: white;
  height: 100%;
  width: 100%;
}

.rate-complex-advansed-parameters-element:not(:first-child){
  margin: auto 0 auto 20px;
}

.request-form{
  width: 100%;
  height: 100%;
  overflow: auto;
}

.compex-rate-page-header-block{
  background: #FFFFFF;
  margin: 0.805% 0;
  border-radius: 10px;
  border: 1px solid #e6eae9;
}

.compex-rate-page-warning {
  margin: 30px 0;
}

.excel-link {
  width: 100%;
  padding-left: 2.5%;
  padding-right: 2.5%;
  text-align: right;
  top: 20px;
  
  > a {
    color: var(--main-orange);
  }
}

.rate-list__main-block__card-container{
  margin-top: 110px;

  @media screen and (max-width: 1200px) {
    margin-top: 50px;
  }
}

</style>
